<template>
    <div class="flex flex-1 flex-col">
        <div class="card flex justify-between border-b border-solid border-gray-200 pb-4">
            <h1>{{ $t('client_details.assigned_employees') }}</h1>
        </div>

        <div v-for="address in assignedEmployeesData" :key="address.id" class="card">
            <div class="flex justify-between border-b pb-2 mb-6">
                <p>
                    <span v-if="address.title">{{ address.title }} - </span>{{ address.street }}, {{ address.post_code }} {{ address.city }}
                </p>
                <p v-if="address.status" class="flex justify-center items-center px-4 py-[5px] rounded text-xs bg-blue-500 text-white">
                    {{ $t(`clients.${address.status}`) }}
                </p>
            </div>

            <div class="grid grid-cols-6 gap-y-10 gap-x-8">
                <div v-for="employee in employeesList" :key="employee.id" class="flex flex-col items-center">
                    <router-link :to="`/employees/${employee.uuid}/information`">
                        <img v-if="employee.avatar_th" :src="employee.avatar_th" class="w-24 h-24 rounded-full border object-cover">
                        <div v-else class="flex justify-center items-center w-24 h-24 border rounded-full">
                            <img src="@/assets/images/unisex_user.png">
                        </div>
                    </router-link>

                    <p class="mt-2 text-xs font-light">
                        {{ employee.name }}
                    </p>
                    <p class="mb-2 text-xs font-light">
                        {{ employee.surname }}
                    </p>

                    <p
                        v-if="checkAssignment(address, employee)"
                        class="flex h-6 justify-center items-center w-full bg-green-500 hover:bg-green-600 transition cursor-pointer rounded text-white text-xs"
                        @click="unassign(address.id, employee.id)"
                    >
                        {{ $t('client_details.assigned') }}
                    </p>
                    <p
                        v-else
                        class="flex h-6 justify-center items-center w-full bg-gray-300 hover:bg-gray-400 transition cursor-pointer rounded text-white text-xs"
                        @click="assign(address.id, employee.id)"
                    >
                        {{ $t('client_details.unassigned') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from './assigned_employees.api';

export default {
    data() {
        return {
            clientUuid:            this.$route.params.uuid,
            assignedEmployeesData: [],
        };
    },

    computed: {
        employeesList() {
            return this.$store.state.employeesActiveDropdown;
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        checkAssignment(address, employee) {
            return address.assigned_employees.filter(item => item.id === employee.id).length;
        },

        async getAll() {
            this.assignedEmployeesData = await Api.getAll(this.clientUuid);
        },

        async assign(addressId, employeeId) {
            try {
                await Api.attach(this.clientUuid, addressId, employeeId);
                this.$notify.success({ title: this.$t('common.success') });
                this.getAll();
            } finally {
                this.$emit('clientDataChanged');
            }
        },

        async unassign(addressId, employeeId) {
            try {
                await Api.detach(this.clientUuid, addressId, employeeId);
                this.$notify.success({ title: this.$t('common.success') });
                this.getAll();
            } finally {
                this.$emit('clientDataChanged');
            }
        },
    },
};
</script>
