export default {
    async getAll(clientUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/assigned_employees`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: assigned_employees.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async attach(clientUuid, addressId, employeeId) {
        try {
            const { data } = await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/assigned_employees/${addressId}/attach/${employeeId}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: assigned_employees.api.js ~ attach ~ error', error);
            throw error;
        }
    },

    async detach(clientUuid, addressId, employeeId) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/assigned_employees/${addressId}/detach/${employeeId}`);
        } catch (error) {
            console.log('🚀 ~ file: assigned_employees.api.js ~ detach ~ error', error);
            throw error;
        }
    },
};
